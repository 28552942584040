import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Hidden } from '@material-ui/core'
import { AnimatePresence, motion } from 'framer-motion'
import AppBar from '@material-ui/core/AppBar'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import logo from '../../assets/pm-logo@2x-noimage.png'
import { AppThemeColors } from '../../constants/Theme'
import useObjectState from '../../hooks/UseObjectState'
import DrawerItem from './DrawerItem'
import SubscribeBanner from '../subscribeBanner/SubscribeBanner'
import { HeaderPropsTypes } from '../../types/UserTypes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: 'fixed',
      width: '100%',
      zIndex: 99,
    },
    appBar: {
      backgroundColor: 'white',
      boxShadow: 'rgb(0 0 0 / 12%) 0 1px 3px',
    },
    drawerMenuContainer: {
      position: 'fixed',
      top: 50,
      width: '100%',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logoContainer: {
      flexGrow: 1,
    },
    menuText: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 700,
      fontSize: 14,
      letterSpacing: 0,
      fontStyle: 'normal',
      textTransform: 'capitalize',
      paddingRight: 30,
    },
    logo: {
      width: 200,
      maxHeight: 38,
      height: 37.5,
      objectFit: 'contain',
    },
  })
)

function Header(props: HeaderPropsTypes): JSX.Element {
  const [state, setState] = useObjectState({
    showDrawer: false,
  })
  const classes = useStyles()

  function onClick() {
    setState({ showDrawer: !state.showDrawer })
  }

  return (
    <div className={classes.root}>
      {!props.isPaid && <SubscribeBanner />}
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={logo} alt="Logo" />
          </div>
          <Hidden xsDown>
            <a
              className={classes.menuText}
              href="https://www.peachmindfulness.com/my-account/">
              My Account
            </a>
          </Hidden>
          <Hidden smUp>
            <Button onClick={onClick} color="default">
              <MenuIcon htmlColor={AppThemeColors.primary} />
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>

      <AnimatePresence>
        {state.showDrawer && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              hidden: {
                opacity: 0,
                y: -5,
              },
              visible: {
                y: 0,
                opacity: 1,
              },
            }}
            className={classes.drawerMenuContainer}>
            <DrawerItem />
          </motion.div>
        )}
        {/* <motion.div
          animate={state.showDrawer ? 'open' : 'closed'}
          variants={{
            open: { opacity: 1, y: 0 },
            closed: { opacity: 0, y: '-100%' },
          }}
          exit={{ opacity: 0, y: '-100%' }}
          className={classes.drawerMenuContainer}>
          <DrawerItem />
        </motion.div> */}
      </AnimatePresence>
    </div>
  )
}

export default Header

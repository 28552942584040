import { AnyAction } from 'redux'
import { TrackReducerState } from '../../types/TrackTypes'
import { FETCH_TRACK } from '../actions/TrackActions'

const initialState: TrackReducerState = {
  track: null,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function TrackReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case FETCH_TRACK: {
      return {
        ...state,
        track: action.payload,
      }
    }

    default:
      return state
  }
}

export default TrackReducer

/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/prefer-default-export
export const parseQueryString = (str: string): any => {
  const objURL: any = {}
  str.replace(
    new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
    (_$0: string, $1: string, _$2: string, $3: string): any => {
      objURL[$1] = $3
    }
  )
  return objURL
}

export function getMintsFromSeconds(seconds: number): number {
  return seconds / 60
}

function convertToZeroOne(value: number): string {
  let label = ''
  if (value < 10) {
    label = `0${value.toFixed(0)}`
  } else {
    label = value.toFixed(0)
  }
  return label
}

export function getMintsLabel(sec: number): string {
  const hours: number | string = Math.floor(sec / 3600) // get hours
  const minutes: number | string = Math.floor((sec - hours * 3600) / 60) // get minutes
  const seconds: number | string = sec - hours * 3600 - minutes * 60 //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  let label = ''

  if (hours > 0) {
    label = `${convertToZeroOne(hours)}:`
  }
  if (minutes > 0) {
    label += `${convertToZeroOne(minutes)}:`
  } else {
    label = '00:'
  }
  if (seconds > 0) {
    label += `${convertToZeroOne(seconds)}`
  } else {
    label += '00'
  }
  return label
}

import { useEffect } from 'react'
import { RootState } from '../config/Store'
import {
  fetchTrackDetails,
  fetchLikeDislikeDetails,
  removeLikeDislike,
  setLikeDislike,
} from '../store/actions/TrackActions'
import {
  UserGetTrackDetailsHookReturnType,
  UserLikeDislikeHookReturnType,
} from '../types/TrackTypes'
import useObjectState from './UseObjectState'
import { useAppDispatch, useAppSelector } from './UseReduxHooks'

function reduxToStore({ tracks }: RootState) {
  return {
    track: tracks?.track,
  }
}

export function useGetTrackDetails(
  step_id: number
): UserGetTrackDetailsHookReturnType {
  const [state, setState] = useObjectState({ loading: true })
  const dispatch = useAppDispatch()

  function callback() {
    setState({ loading: false })
  }

  useEffect(() => {
    dispatch(fetchTrackDetails({ step_id }, callback))
  }, [])
  const { track } = useAppSelector(reduxToStore)
  // const audioPeaks =
  //   '[0.12158574163913727,-0.030670166015625,0.07602160423994064,-0.04144287109375,0.05301064997911453,-0.132598876953125,0.17218543589115143,-0.374298095703125,0.08227790147066116,-0.32611083984375,0.14731284976005554,-0.1854248046875,0.23557236790657043,-0.180145263671875,0.20825830101966858,-0.210235595703125,0.286812961101532,-0.458343505859375,0.4426709711551666,-0.4293212890625,0.36222419142723083,-0.23822021484375,0.31296730041503906,-0.417938232421875,0.2927030324935913,-0.40277099609375,0.3088778257369995,-0.23565673828125,0.15243995189666748,-0.30126953125,0.28351694345474243,-0.156768798828125,0.2950224280357361,-0.433380126953125,0.2373424470424652,-0.132598876953125,0.2641071677207947,-0.384521484375,0.2862941324710846,-0.17950439453125,0.31443220376968384,-0.4097900390625,0.14145329594612122,-0.2532958984375,0.08191168308258057,-0.463836669921875,0.33829766511917114,-0.37408447265625,0.3980224132537842,-0.3094482421875,0.4357737898826599,-0.171722412109375,0.4498428404331207,-0.25,0.28827783465385437,-0.41107177734375,0.2944120466709137,-0.177734375,0.3974730670452118,-0.36614990234375,0.34784996509552,-0.339202880859375,0.4504226744174957,-0.3914794921875,0.33668020367622375,-0.25592041015625,0.12540055811405182,-0.426300048828125,0.20981475710868835,-0.362335205078125,0.3395184278488159,-0.271087646484375,0.44062623381614685,-0.379302978515625,0.08383434265851974,-0.425384521484375,0.10562456399202347,-0.124603271484375,0.07623523473739624,-0.11700439453125]'
  // if (track) {
  //   track.audioPeaks = audioPeaks
  // }
  return { track, loading: state.loading }
}

export function useLikeAndDislike(
  step_id: number
): UserLikeDislikeHookReturnType {
  const [state, setState] = useObjectState({
    isLiked: 0,
    loading: true,
  })
  const dispatch = useAppDispatch()

  function callback(isLiked: null | number | boolean) {
    if (isLiked && typeof isLiked === 'number') {
      const likeValue = isLiked
      setState({ isLiked: likeValue, loading: false })
    } else {
      setState({ loading: false })
    }
  }

  useEffect(() => {
    dispatch(fetchLikeDislikeDetails({ step_id }, callback))
  }, [])

  function onLike() {
    let value = state.isLiked
    if (value === 0 || value === -1) {
      value = 1
      dispatch(setLikeDislike({ step_id, value }, () => null))
    } else if (value === 1) {
      value = 0
      dispatch(removeLikeDislike({ step_id }, () => null))
    }
    setState({ isLiked: value })
  }

  function onDisLike() {
    let value = state.isLiked
    if (value === 0 || value === 1) {
      value = -1
      dispatch(setLikeDislike({ step_id, value }, () => null))
    } else if (value === -1) {
      value = 0
      dispatch(removeLikeDislike({ step_id }, () => null))
    }
    setState({ isLiked: value })
  }

  return { isLiked: state.isLiked, loading: state.loading, onLike, onDisLike }
}

export default useGetTrackDetails

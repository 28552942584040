import { useEffect, useRef } from 'react'
import WaveSurfer from 'wavesurfer.js'
import useObjectState from '../../hooks/UseObjectState'
import { TrackPlayerWaveProps } from '../../types/TrackTypes'

function WaveFormWaves(props: TrackPlayerWaveProps): JSX.Element {
  const [state] = useObjectState({ prevSeek: 0 })
  const waveSurfer = useRef<WaveSurfer>()

  useEffect(() => {
    // will set audio peaks if not stored yet.
    waveSurfer.current = WaveSurfer.create({
      container: `#track-${props.track.step_id}-waveform`,
      progressColor: '#f50',
      barHeight: 3,
      barWidth: 3,
      cursorWidth: 0,
      height: props.height,
      waveColor: props.color,
      responsive: true,
      normalize: true,
      backend: 'MediaElement',
    })
    if (waveSurfer.current) {
      if (props.track?.audioPeaks) {
        const peaks = JSON.parse(props?.track?.audioPeaks)
        waveSurfer.current.load(props?.track?.audio[props.soundSelectedIndex].sound_file, peaks)
      } else {
        waveSurfer.current.load(props?.track?.audio[props.soundSelectedIndex].sound_file)
      }

      waveSurfer.current.setMute(true)

      const start = props.prevProg ? props.prevProg : 0

      waveSurfer.current.on('ready', () => {
        if (waveSurfer.current) {
          waveSurfer.current.seekTo(start)

          waveSurfer.current.on('seek', (progress) => {
            props.seekWaveForm(progress)
            if (!props.playing) {
              props.setPlayPause(
                !props?.playing,
                props?.track?.step_id,
                progress
              )
            }
          })
        }
      })
    }
  }, [props.soundSelectedIndex])

  useEffect(() => {
    if (!waveSurfer.current) return
    if (props.playing !== waveSurfer.current.isPlaying()) {
      waveSurfer.current.playPause()
    }
    if (!props.playing) return
    if (props.prevSeek !== state.prevSeek) {
      waveSurfer.current.seekTo(props.prevSeek)
    }
  })

  return (
    <div>
      <div id={`track-${props.track.step_id}-waveform`}></div>
    </div>
  )
}

export default WaveFormWaves

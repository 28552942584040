import { useEffect } from 'react'
import { RootState } from '../config/Store'
import { fetchUserSubscriptionDetails } from '../store/actions/UserAction'
import { UserSubscriptionHookReturnType } from '../types/TrackTypes'
import { useAppDispatch, useAppSelector } from './UseReduxHooks'

function reduxToStore({ user }: RootState) {
  return {
    isPaid: user.user?.paid,
  }
}

export function useUserSubscription(
  step_id: number
): UserSubscriptionHookReturnType {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchUserSubscriptionDetails({ step_id }))
  }, [])
  const { isPaid } = useAppSelector(reduxToStore)
  return { isPaid: isPaid || false }
}

export default useUserSubscription

import { Provider } from 'react-redux'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import 'font-awesome/css/font-awesome.min.css'
import MindfulnessDetails from './pages/mindfulness/MindfulnessDetails'
// eslint-disable-next-line import/no-unresolved
import './stylesheets/Application.scss'
import store from './config/Store'

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route component={MindfulnessDetails} exact path="/meditation/:step_id" />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App

import { useState } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'
import TrackPlayer from '../../components/trackPlayer/TrackPlayer'
import { useGetTrackDetails } from '../../hooks/UseTracks'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { useUserSubscription } from '../../hooks/UserHooks'

type MindfulnessDetailsParams = {
  step_id?: string | undefined
}

function MindfulnessDetails(): JSX.Element {
  const params = useParams<MindfulnessDetailsParams>()

  let step_id = 0
  if (params?.step_id) {
    step_id = parseInt(params.step_id, 10)
  }
  const { loading, track } = useGetTrackDetails(step_id)
  const { isPaid } = useUserSubscription(step_id)
  const [soundSelectedIndex, setSoundIndex] = useState(0)
  if (loading || !track) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <PulseLoader color="#ff7c4f" size="20" margin="5" />
      </div>
    )
  }

  function onSoundSelection(soundIndex: number) {
    setSoundIndex(soundIndex)
  }
  const isSleepStory = track.type === 'sleep story'
  return (
    <>
      <Header isPaid={isPaid}/>
      <Grid container>
        <Grid container sm={12} lg={12} md={12} justifyContent="center">
          <Grid
            item
            style={{ marginTop: 100, paddingLeft: 20, paddingRight: 40 }}
            className="card-light-primary"
            sm={12}
            lg={10}
            md={10}>
            <Hidden xsDown>
              <div>
                <h2 className="heading-h2 text-center ">
                  {isSleepStory
                    ? 'Ease Into The Night.'
                    : 'Get Mindfulness Into Your Day.'}
                </h2>
                <div className="sep-shadow" />
              </div>
            </Hidden>
            <h3 className="heading-h3 text-center">
              {isSleepStory ? 'Relaxing Meditation' : 'Morning Meditation'}
            </h3>
            <Hidden smUp>
              <h5 className="heading-h5 text-center">{track.name}</h5>
            </Hidden>
            <Hidden xsDown>
              <h5 className="heading-h5 text-center color-primary margin-0">
                {isSleepStory
                  ? 'Clear up thoughts and ease into sleep… effortlessly.'
                  : 'Get clarity, focus and energy for the day.'}
              </h5>
            </Hidden>
            <Grid
              item
              justifyContent="center"
              sm={12}
              lg={12}
              md={12}
              className="sound-medias margin-0">
              {track.audio.map((audioItem, index) => (
                <h5
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => onSoundSelection(index)}
                  className={`sound-medias-item heading-h5 font-size-18 margin-0 ${
                    soundSelectedIndex === index ? 'color-primary' : ''
                  }`}>
                  {audioItem.label}
                </h5>
              ))}
            </Grid>
            <Hidden xsDown>
              <div className="vertical-space-20" />
            </Hidden>
            <Hidden smUp>
              <img
                className="mindfullnes-img"
                alt="Gentle Awareness"
                src={track.image}
              />
            </Hidden>
            <Hidden xsDown>
              <h5 className="heading-h5 font-size-18">{track.name}</h5>
            </Hidden>
            <TrackPlayer
              soundSelectedIndex={soundSelectedIndex}
              track={track}
            />
          </Grid>
        </Grid>
        <Grid container sm={12} lg={12} md={12} justifyContent="center">
          <Grid
            item
            style={{ marginTop: 100, paddingLeft: 20, paddingRight: 20 }}
            sm={12}
            lg={10}
            md={10}>
            <section>
              <div className="textwidget">
                <h5
                  style={{
                    textAlign: 'center',
                    fontSize: 20,
                    lineHeight: '1.3',
                  }}
                  data-lineheight="26px"
                  className="heading-h5">
                  <a
                    href="https://www.peachmindfulness.com/terms-and-conditions/"
                    rel="noopener"
                    className="link-primary">
                    TERMS &amp; CONDITIONS
                  </a>{' '}
                  |{' '}
                  <a
                    href="https://www.peachmindfulness.com/privacy-policy/"
                    rel="noopener"
                    className="link-primary">
                    PRIVACY POLICY
                  </a>
                </h5>
                <p className="text-center address-text">
                  370 Wabasha St N #500, St Paul, MN 55102.
                </p>
              </div>
              <div style={{ clear: 'both' }} />
            </section>
            <section
              id="social_links-widget-2"
              className="fusion-widget-mobile-align-initial fusion-widget-align-center fusion-footer-widget-column widget social_links"
              style={{ textAlign: 'center' }}>
              <div className="fusion-social-networks boxed-icons">
                <div className="fusion-social-networks-wrapper">
                  <a
                    className="fusion-social-network-icon fa fa-facebook"
                    href="https://www.facebook.com/peachmindfulness"
                    title="Facebook"
                    aria-label="Facebook"
                    rel="noopener noreferrer"
                    target="_self"
                    style={{
                      borderRadius: '50px',
                      padding: '8px',
                      fontSize: '18px',
                      color: '#FF7C4F',
                      backgroundColor: '#FFFBF7',
                      borderColor: '#FFFBF7',
                    }}
                  />
                  <a
                    className="fusion-social-network-icon fa fusion-tooltip fa-youtube"
                    href="https://www.youtube.com/channel/UCIAt2HOXuyNptpC5u1LkNZw"
                    title="YouTube"
                    aria-label="YouTube"
                    rel="noopener noreferrer"
                    target="_self"
                    style={{
                      borderRadius: '50px',
                      padding: '8px',
                      fontSize: '18px',
                      color: '#FF7C4F',
                      backgroundColor: '#FFFBF7',
                      borderColor: '#FFFBF7',
                    }}
                  />
                  <a
                    className="fusion-social-network-icon fa fusion-tooltip fa-instagram"
                    href="https://www.instagram.com/peachmindfulness/"
                    title="Instagram"
                    aria-label="Instagram"
                    rel="noopener noreferrer"
                    target="_self"
                    style={{
                      borderRadius: '50px',
                      padding: '8px',
                      fontSize: '18px',
                      color: '#FF7C4F',
                      backgroundColor: '#FFFBF7',
                      borderColor: '#FFFBF7',
                    }}
                  />
                  <a
                    className="fusion-social-network-icon fa fa-pinterest"
                    href="https://www.pinterest.com/peachmindfulness/"
                    title="Pinterest"
                    aria-label="Pinterest"
                    rel="noopener noreferrer"
                    target="_self"
                    style={{
                      borderRadius: '50px',
                      padding: '8px',
                      fontSize: '18px',
                      color: '#FF7C4F',
                      backgroundColor: '#FFFBF7',
                      borderColor: '#FFFBF7',
                    }}
                  />
                </div>
              </div>
              <div style={{ clear: 'both' }} />
            </section>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}

export default MindfulnessDetails

import { configureStore } from '@reduxjs/toolkit'
import tracks from './reducers/TrackReducer'
import user from './reducers/UserReducer'

const rootReducer = configureStore({
  reducer: {
    tracks,
    user,
  },
})

export default rootReducer

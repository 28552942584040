import AxiosInstance from '../../config/Axios'
import { AppThunk } from '../../config/Store'
import ErrorHelper from '../../helpers/ErrorHelpers'
import {
  TrackActionCallback,
  TrackActionParam,
  TrackParseQueryResponse,
} from '../../types/TrackTypes'
import { parseQueryString } from '../../utils/Utils'

const params: TrackParseQueryResponse = parseQueryString(window.location.search)
// Actions
export const FETCH_TRACK = 'FETCH_TRACK'

const alias_id = parseInt(params.ver_id || 728128, 10)

export const fetchTrackDetails =
  (data: TrackActionParam, callback: TrackActionCallback): AppThunk =>
  async (dispatch) => {
    try {
      const apiRes = await AxiosInstance({
        method: 'GET',
        url: `/v1/step/${data.step_id}?user=${alias_id}`,
      })
      dispatch({
        type: FETCH_TRACK,
        payload: apiRes.data,
      })
      callback(true)
    } catch (error) {
      ErrorHelper(error)
      callback(false)
    }
  }

export const fetchLikeDislikeDetails =
  (data: TrackActionParam, callback: TrackActionCallback): AppThunk =>
  async () => {
    try {
      const apiRes = await AxiosInstance({
        method: 'GET',
        url: `/v1/user/${alias_id}/annotation/like/step/${data.step_id}`,
      })
      const isLiked = typeof apiRes.data === 'object' ? 0 : apiRes.data
      callback(isLiked)
    } catch (error) {
      ErrorHelper(error)
      callback(false)
    }
  }

export const setLikeDislike =
  (data: TrackActionParam, callback: TrackActionCallback): AppThunk =>
  async () => {
    try {
      const payload = data.value
      const headers = {
        'Content-Type': 'text/plain',
      }
      const apiRes = await AxiosInstance({
        method: 'PUT',
        headers,
        url: `/v1/user/${alias_id}/annotation/like/step/${data.step_id}`,
        data: payload,
      })
      if (callback) {
        callback(apiRes.data)
      }
    } catch (error) {
      ErrorHelper(error)
      if (callback) {
        callback(false)
      }
    }
  }

export const removeLikeDislike =
  (data: TrackActionParam, callback: TrackActionCallback): AppThunk =>
  async () => {
    try {
      const apiRes = await AxiosInstance({
        method: 'DELETE',
        url: `/v1/user/${alias_id}/annotation/like/step/${data.step_id}`,
      })
      if (callback) {
        callback(apiRes.data)
      }
    } catch (error) {
      ErrorHelper(error)
      if (callback) {
        callback(false)
      }
    }
  }

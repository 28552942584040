function SubscribeBanner(): JSX.Element {
  return (
    <div className="subscription-banner">
      <h6 className="subscription-text">
        Continue to have your daily meditation and sleep stories delivered to
        you with Peach membership at:{' '}
        <a href="http://bit.ly/peach-sc" className="color-primary">
          http://bit.ly/peach-sc
        </a>
      </h6>
    </div>
  )
}

export default SubscribeBanner

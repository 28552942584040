import { Hidden } from '@material-ui/core'
import { useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import useObjectState from '../../hooks/UseObjectState'
import { useLikeAndDislike } from '../../hooks/UseTracks'
import {
  TrackPlayerCurrentState,
  TrackPlayerOnProgressType,
  TrackPlayerProps,
} from '../../types/TrackTypes'
import { getMintsLabel } from '../../utils/Utils'
import WaveFormContainer from './Waveform'

function TrackPlayer(props: TrackPlayerProps): JSX.Element {
  const [state, setState] = useObjectState({
    volume: 0.8,
    muted: false,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    played: 0,
    startT: null,
  })

  const [trackCurrentState, setCurrentTrackState] =
    useObjectState<TrackPlayerCurrentState>({
      currentTrack: props.track,
      playing: false,
      player: null,
      trackId: props.track.step_id,
      playerSeek: 0,
      waveSeek: 0,
      progressTrackId: { [`${props.track.step_id}`]: 0 },
    })

  const { isLiked, loading, onLike, onDisLike } = useLikeAndDislike(
    props.track.step_id
  )
  const player = useRef<ReactPlayer>(null)
  function setProg(trackId: number, progress: number) {
    const progressTrackId = { [`${trackId}`]: progress || 0 }
    setCurrentTrackState({ progressTrackId })
  }

  useEffect(() => {
    // this is for the circumstance where

    const trackProg =
      trackCurrentState?.progressTrackId?.[`${props.track.step_id}`]
    const thisId = props?.track?.step_id

    let progress = 0
    if (player.current) {
      progress = player.current.getCurrentTime() / player.current.getDuration()
    }
    const prog = trackProg || progress
    setProg(thisId, prog)
  }, [props.soundSelectedIndex])

  function endCurrentTrack() {
    const oldState = trackCurrentState
    if (oldState) {
      const trackplayer = {
        ...oldState,
        playing: false,
        waveSeek: 0,
        playerSeek: 0,
        progressTrackId: { [oldState.trackId]: 0 }, // setting leaving track progress out
      }
      setCurrentTrackState(trackplayer)
    }
  }

  function onEnded() {
    endCurrentTrack()
  }

  function onProgress() {
    return (state_: TrackPlayerOnProgressType) => {
      if (!state_.seeking) {
        setState(state_)
      }
    }
  }

  function onDuration() {
    return (duration: number) => {
      setState({ duration })
    }
  }

  function keepProgress() {
    if (state.startT !== null) {
      const startTime = state.startT
      setState({ startT: null })
      if (player.current && startTime && state?.duration)
        player.current.seekTo(startTime * state?.duration)
    }
  }

  function seekWaveForm(progress: number) {
    // setState({ duration:progress });
    if (player.current) player.current.seekTo(progress)
    // setPlayPause(true,trackCurrentState.trackId,progress)
  }

  function setPlayPause(boolean: boolean, trackId: number, progress: number) {
    const action = { boolean, trackId, progress }
    const oldState = trackCurrentState
    const trackplayer = {
      ...oldState,
      playing: boolean, // action.boolean,
      trackId: action.trackId,
      waveSeek: oldState?.progressTrackId?.[`${action.trackId}`] || 0,
      playerSeek: oldState?.progressTrackId?.[`${action.trackId}`] || 0,
      progressTrackId: { [`${action.trackId}`]: action.progress }, // setting leaving track progress out
    }
    setCurrentTrackState(trackplayer)
  }

  function songButton() {
    const { track } = props
    const { playing, trackId } = trackCurrentState || {}
    const trackProg =
      trackCurrentState?.progressTrackId?.[`${props.track.step_id}`]
    const tplayer = player.current
    if (trackId === -1) {
      // no song played previously
      setPlayPause(!playing, track.step_id, 0)
    } else if (track.step_id === trackId) {
      // if we are pausing the same song
      if (tplayer) {
        const prog =
          trackProg || tplayer.getCurrentTime() / tplayer.getDuration()

        setPlayPause(!playing, track.step_id, prog)
      }
    } else {
      // not same track
      const prog = trackProg || 0 // if previous pause ,pick that, if never played start at 0

      setPlayPause(!playing, track.step_id, prog)
    }
  }

  function getSelectedSound() {
    if (!props.track) {
      return {
        trackToPlay: '',
        trackImage: 'https://image.flaticon.com/icons/svg/3/3722.svg',
        trackUploader: '',
        trackName: '',
        likeButton: 'liked-button',
        linkToUploader: '/#/tracks',
      }
    }
    const cTrack = props.track
    return {
      trackToPlay: cTrack.audio[props.soundSelectedIndex].sound_file,
      trackImage: cTrack.image,
      trackName: cTrack.name,
    }
  }

  const { track } = props
  const buttonPlaying =
    trackCurrentState?.playing && trackCurrentState?.trackId === track?.step_id

  const { playing } = trackCurrentState
  const { loop, volume, muted } = state
  const { trackToPlay } = getSelectedSound()

  return (
    <>
      <div className="track-item-container">
        <div className="track-item">
          <Hidden xsDown>
            <div className="track-image-box">
              <a>
                <img alt="" className="track-player-img" src={track.image} />
              </a>
            </div>
          </Hidden>

          <section className="track-details">
            <div className="td-top">
              <button
                className="playButton"
                role="application"
                title="Play"
                onClick={songButton}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43">
                  <defs>
                    <linearGradient
                      id="playButton__gradient18"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                      spreadMethod="pad">
                      <stop offset="0%" stopColor="#ff7700" stopOpacity={1} />
                      <stop offset="100%" stopColor="#ff4400" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <circle
                    fill="url(#playButton__gradient18)"
                    stroke="#cc5f00"
                    cx="21.5"
                    cy="21.5"
                    r={21}
                  />
                  <circle
                    className="playButton__overlay"
                    fill="#000"
                    fillOpacity="0.08"
                    stroke="#cc5f00"
                    cx="21.5"
                    cy="21.5"
                    r={21}
                  />
                  {buttonPlaying ? (
                    <g fill="#fff" className="playButton__pause">
                      <rect x={15} y={12} width={5} height={19} />
                      <rect x={23} y={12} width={5} height={19} />
                    </g>
                  ) : (
                    <path
                      className="playButton__play"
                      fill="#fff"
                      d="M31,21.5L17,33l2.5-11.5L17,10L31,21.5z"
                    />
                  )}
                </svg>
              </button>

              <div className="ti-upload-det">
                <a className="ti-title">{track.name}</a>
              </div>
            </div>
            <div className="sound-bar">
              <span></span>
              <WaveFormContainer
                playing={trackCurrentState.playing || false}
                seekWaveForm={seekWaveForm}
                track={track}
                soundSelectedIndex={props.soundSelectedIndex}
                setPlayPause={setPlayPause}
                prevSeek={trackCurrentState?.playerSeek || 0}
                prevProg={
                  trackCurrentState?.progressTrackId?.[
                    `${props.track.step_id}`
                  ] || 0
                }
                height={60}
                color={'#000'}
              />
              <div className="left-player-time">
                <p className="time-player-length color-primary">
                  {' '}
                  {getMintsLabel(state.played * state.duration)}{' '}
                </p>
              </div>

              <div className="right-player-time">
                <p className="time-player-length color-gray">
                  {' '}
                  {getMintsLabel(state.duration)}{' '}
                </p>
              </div>
            </div>
          </section>

          <ReactPlayer
            ref={player}
            width="0%"
            height="0%"
            url={trackToPlay}
            playing={playing}
            loop={loop || false}
            volume={volume}
            muted={muted}
            progressInterval={50}
            onEnded={onEnded}
            onProgress={onProgress()}
            onDuration={onDuration()}
            onReady={keepProgress}
          />
        </div>
      </div>

      <div className="top-right-item">
        <div className="top-right-buttons">
          <button
            onClick={onLike}
            title="Like"
            className={`like-button ${isLiked === 1 && 'like-button-active'}`}>
            {loading ? '...' : <span className="fa fa-thumbs-up" />}
          </button>
          <button
            title="Dislike"
            onClick={onDisLike}
            className={`like-button ${isLiked === -1 && 'like-button-active'}`}>
            {loading ? '...' : <span className="fa fa-thumbs-down" />}
          </button>
        </div>
      </div>
    </>
  )
}
export default TrackPlayer

import AxiosInstance from '../../config/Axios'
import { AppThunk } from '../../config/Store'
import ErrorHelper from '../../helpers/ErrorHelpers'
import { TrackParseQueryResponse } from '../../types/TrackTypes'
import { UserSubscriptionActionParam } from '../../types/UserTypes'
import { parseQueryString } from '../../utils/Utils'

const params: TrackParseQueryResponse = parseQueryString(window.location.search)
// Actions
export const FETCH_USER_SUBSCRIPTION = 'FETCH_USER_SUBSCRIPTION'

const alias_id = parseInt(params.ver_id || 728128, 10)

export const fetchUserSubscriptionDetails =
  (data: UserSubscriptionActionParam): AppThunk =>
  async (dispatch) => {
    try {
      const apiRes = await AxiosInstance({
        method: 'GET',
        url: `/verification/user/${alias_id}/${data.step_id}/0`,
      })
      dispatch({
        type: FETCH_USER_SUBSCRIPTION,
        payload: apiRes.data,
      })
    } catch (error) {
      ErrorHelper(error)
    }
  }

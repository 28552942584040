function DrawerItem(): JSX.Element {
  return (
    <div className="drawer-item">
      <h4 className="drawer-item-text">
        <a href="https://www.peachmindfulness.com/my-account/">My Account</a>
      </h4>
    </div>
  )
}

export default DrawerItem

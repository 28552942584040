import { AnyAction } from 'redux'
import { UserReducerState } from '../../types/UserTypes'
import { FETCH_USER_SUBSCRIPTION } from '../actions/UserAction'

const initialState: UserReducerState = {
  user: null,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function UserReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case FETCH_USER_SUBSCRIPTION: {
      return {
        ...state,
        user: action.payload,
      }
    }

    default:
      return state
  }
}

export default UserReducer

const currentYear = new Date().getFullYear()

function Footer(): JSX.Element {
  return (
    <footer id="footer">
      <div className="copyright-content">
        <h4 className="footer-text">
          {' '}
          © Copyright {currentYear} Peach Mindfulness | All Rights Reserved
        </h4>
      </div>
    </footer>
  )
}

export default Footer
